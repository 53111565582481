import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { HOSTNAME } from "..";

const firebaseConfig = {
  apiKey: "AIzaSyASkgBy0I2l8Wi8n5OltY8iSuJaTOsv-L8",
  authDomain: "muto-onlinesystem.firebaseapp.com",
  databaseURL: "https://muto-onlinesystem.firebaseio.com",
  projectId: "muto-onlinesystem",
  storageBucket: "muto-onlinesystem.appspot.com",
  messagingSenderId: "45667916408",
  appId: "1:45667916408:web:ed947aa855e014cf916b8d",
  measurementId: "G-YWJTGSHYJY"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: "BL6F7jLU2hX7vCw_p8Q9HOCVTVGc0Gj6et4_8xSFTkbSgbH-kiGStAPAI17n1RQRdufGzuelUYjrK08JLgLDDM8" })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        let json = JSON.stringify({'deviceToken' : currentToken})
        fetch(HOSTNAME + '/addDeviceToken', {
          credentials: 'include',
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: json
        })
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      return resolve(payload);
    });
  });
