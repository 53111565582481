import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Login.css';
import './Main.css';
import './Profile.css';
import { Main } from './Main';
import { Login } from './Login';
import { Profile } from './Profile';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PushNotification } from './components/Notification';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const HOSTNAME = 'https://api.moscord.xyz'
export const WS_HOSTNAME = 'wss://api.moscord.xyz/ws'
//export const HOSTNAME = 'http://localhost:8888'
//export const WS_HOSTNAME = 'ws://localhost:8888/ws'
export const VERSION = 1.32

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={`/login`} element={<Login type="login" />} />
        <Route path={`/logout`} element={<Login type="logout" />} />
        <Route path={`/profile`} element={<Profile />} />
        <Route path={`/`} element={<Main />} />
        <Route path={`/side`} element={<Main />} />
        <Route path={`/chat/:room`} element={<Main />} />
        <Route path={`/file/:src`} element={<Main />} />
      </Routes>
    </BrowserRouter>

    <PushNotification />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
