import { useState } from "react";
import { requestForToken, onMessageListener } from "./firebase";

export const PushNotification = () => {
  const [isActive, setIsActive] = useState(true)
  requestForToken();

  onMessageListener()
    .then((payload) => {
      console.log(payload)
      if (!isActive) {
        console.log('hidden')
        new Notification(payload.Notification, {
          body: payload.Notification.body,
          icon: payload.Notification.icon,
          tag: payload.Notification.tag,
        });
      }
    })
    .catch((err) => console.log("failed: ", err));
  
  window.addEventListener('focus', () => {setIsActive(true)}, false);
  window.addEventListener('blur', () => {setIsActive(false)}, false);

  return <div />;

};
