import { styled } from 'styled-components';

const StyledLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #1e311f;
  z-index: 999;
`

const StyledError = styled(StyledLoading)`
  z-index: 9999;
`

const StyledLoadingPart = styled(StyledLoading)`
  margin: 0;
  background: #1e311f88;
  border-radius: 10px;
`

const StyledLoadingContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  text-align: center;
`

const StyledCredit = styled.p`
  font-family: 'Sigmar', sans-serif;
`

const StyledLoadingSpinner = styled.div`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: dotted 0.6rem;
  border-radius: 100%;
  font-family: cursive;
  animation: rotate 1s ease-in-out 0s infinite;

  @keyframes rotate {
    from {
      transform: rotate(0);
    } to {
      transform: rotate(360deg);
    }
  }
`

export const Loading  = (props : any) => {
  if (props.error != null && props.error === true) {
    return (
      <StyledError>
        <StyledLoadingContent>
          <p>問題が発生しました。時間をおいて再度お試しください。</p>
          <StyledCredit>Muto Online System CORDial version</StyledCredit>
        </StyledLoadingContent>
      </StyledError>
    )
  } else {
    return (
      <StyledLoading>
        <StyledLoadingContent>
          <StyledLoadingSpinner />
        </StyledLoadingContent>
      </StyledLoading>
    )
  }
}

export const LoadingPart  = () => {
  return (
    <StyledLoadingPart>
      <StyledLoadingContent>
        <StyledLoadingSpinner />
      </StyledLoadingContent>
    </StyledLoadingPart>
  )
}
