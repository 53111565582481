import { Link } from 'react-router-dom';
import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { UseModalOptions, useModal } from 'react-hooks-use-modal';
import { requestForToken } from './components/firebase';
import imageCompression from 'browser-image-compression';
import { HOSTNAME } from '.';
import { LoadingPart } from './Loading';

export const Profile = () => {
  type User = {
    uniqueName: string
    displayName: string
    iconPath: string
  }
  let initProfile: User = {
    uniqueName: '',
    displayName: '',
    iconPath: '',
  }
  const [profile, setProfile] = useState(initProfile)
  const modalOpt: UseModalOptions<Record<string, unknown>> = {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    }
  }
  const [IcModal, icOpen, icClose] = useModal('root', modalOpt)
  const [DnModal, dnOpen, dnClose] = useModal('root', modalOpt)
  const [UnModal, unOpen, unClose] = useModal('root', modalOpt)
  const [PwModal, pwOpen, pwClose] = useModal('root', modalOpt)

  const icInputRef = useRef<HTMLInputElement>(null)
  const icErrRef = useRef<HTMLParagraphElement>(null)
  const [imgSrc, setImgSrc] = useState('')

  const dnInputRef = useRef<HTMLInputElement>(null)
  const dnErrRef = useRef<HTMLParagraphElement>(null)

  const unInputRef = useRef<HTMLInputElement>(null)
  const unErrRef = useRef<HTMLParagraphElement>(null)

  const pwOldRef = useRef<HTMLInputElement>(null)
  const pwNewRef = useRef<HTMLInputElement>(null)
  const pwNew2Ref = useRef<HTMLInputElement>(null)
  const pwSameErrRef = useRef<HTMLParagraphElement>(null)
  const pwSendErrRef = useRef<HTMLParagraphElement>(null)

  const [loading, setLoading] = useState(false)
  const [sendFile, setSendFile] = useState<File | null>()

  const pwSameCheck = () => {
    if (pwNewRef.current != null && pwSameErrRef.current != null && pwNew2Ref.current != null) {
      if (pwNewRef.current.value !== pwNew2Ref.current.value) {
        pwSameErrRef.current.hidden = false
      } else {
        pwSameErrRef.current.hidden = true
      }
    }
  }

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      let fr = new FileReader()
      try {
        fr.readAsDataURL(event.target.files[0])
        fr.onload = async () => {
          setLoading(true)
          if (event.target.files != null &&
              event.target.files[0] != null &&
              event.target.files[0].type.startsWith('image/') &&
              icInputRef.current != null)
          {
            const img = event.target.files[0]
            try {
              const cf = await imageCompression(img, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1024
              })
              setSendFile(cf)
              const url = await imageCompression.getDataUrlFromFile(cf)
              setImgSrc(url)
            } catch (e) {
              console.error(e)
              if (icErrRef.current != null) {
                icErrRef.current.hidden = false
              }
              setSendFile(null)
            }
          }
          setLoading(false)
        }
      } catch (e) {
        if (icErrRef.current != null) {
          icErrRef.current.hidden = false
          setSendFile(null)
        }
        console.error(e)
      }
    }
  }

  const fileUpload = () => {
    if (icInputRef.current != null) {
      icInputRef.current.click();
    }
  }

  const [updateProfile, setUpdateProfile] = useState(0)
  useEffect(() => {
    let url = HOSTNAME + '/getProfile'
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          console.log(response.statusText)
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (data != null) {
          var parseData = JSON.parse(data) as User
          parseData.iconPath = HOSTNAME + '/' + parseData.iconPath
          setProfile(parseData)
        }
      })
  }, [updateProfile])

  const changeIcon = () => {
    sendChangeIcon(sendFile).then(res => {
      if (res) {
        setUpdateProfile(updateProfile+1)
        icClose()
      } else {
        if (icErrRef.current != null) {
          icErrRef.current.hidden = false
        }
      }
    })
  }

  const changeDn = () => {
    sendChangeDn(dnInputRef).then(res => {
      if (res) {
        setUpdateProfile(updateProfile+1)
        dnClose()
      } else {
        if (dnErrRef.current != null) {
          dnErrRef.current.hidden = false
        }
      }
    })
  }

  const changeUn = () => {
    sendChangeUn(unInputRef).then(res => {
      if (res) {
        setUpdateProfile(updateProfile+1)
        unClose()
      } else {
        if (unErrRef.current != null) {
          unErrRef.current.hidden = false
        }
      }
    })
  }

  const changePw = () => {
    if (pwSameErrRef.current != null && pwSameErrRef.current.hidden) {
      sendChangePw(pwOldRef, pwNewRef).then(res => {
        if (res) {
          setUpdateProfile(updateProfile+1)
          pwClose()
        } else {
          if (pwSendErrRef.current != null) {
            pwSendErrRef.current.hidden = false
          }
        }
      })
    }
  }

  return (
  <main>
    <div className='profile-main'>
      <div className='profile-header'>
        <Link to={`/`}><i className="bi bi-arrow-left"></i></Link>
      </div>

      <div className='profile'>
        <h2>アカウント設定</h2>

        <div className='profile-col'>
          <p className='col-title col-item'>アイコン</p>
          <p className='col-item'>
            <img width='100px' height='100px' src={profile.iconPath} alt='' />
          </p>
          <p className='col-item col-btn'>
            <button className='edit-btn' onClick={icOpen}>編集</button>
          </p>
        </div>
        <IcModal>
          <div className={loading ? 'modal not-touch' : 'modal'}>
            { loading ? <LoadingPart /> : null }
            <img width='100px' height='100px' src={imgSrc} className={imgSrc !== '' ? "img-preview" : "img-preview hide"} alt="" />
            <input type="button"
              className='ic-btn'
              onClick={fileUpload}
              value="新しいアイコンを選択" />
            <input
              hidden
              ref={icInputRef}
              type="file"
              onChange={onFileInputChange}
              accept='image/*' />
            <p hidden ref={icErrRef} className="error">失敗しました。</p>
            <p className="modal-btns">
              <button className='edit-btn' onClick={changeIcon}>決定</button>
              <button className='edit-btn' onClick={() => {
                icClose()
                setImgSrc('')
              }}>キャンセル</button>
            </p>
          </div>
        </IcModal>

        <div className='profile-col'>
          <p className='col-title col-item'>表示名</p>
          <p className='col-item'>{profile.displayName}</p>
          <p className='col-item col-btn'>
            <button className='edit-btn' onClick={dnOpen}>編集</button>
          </p>
        </div>
        <DnModal>
          <div className='modal'>
          <p>
            <input
              type='text'
              ref={dnInputRef}
              placeholder='新しい表示名'
              /></p>
          <p hidden ref={dnErrRef} className="error">失敗しました。</p>
          <p className="modal-btns">
            <button className='edit-btn' onClick={changeDn}>決定</button>
            <button className='edit-btn' onClick={dnClose}>キャンセル</button>
          </p>
          </div>
        </DnModal>

        <div className='profile-col'>
          <p className='col-title col-item'>ユーザー名</p>
          <p className='col-item'>{profile.uniqueName}</p>
          <p className='col-item col-btn'>
            <button className='edit-btn' onClick={unOpen}>編集</button>
          </p>
        </div>
        <UnModal>
          <div className='modal'>
          <p>
            <input
              type='text'
              ref={unInputRef}
              placeholder='新しいユーザ名'
              />
          </p>
          <p hidden ref={unErrRef} className="error">
              失敗しました。同一のユーザが存在する可能性があります。
          </p>
          <p className='modal-btns'>
            <button className='edit-btn' onClick={changeUn}>決定</button>
            <button className='edit-btn' onClick={unClose}>キャンセル</button>
          </p>
          </div>
        </UnModal>

        <div className='profile-col'>
          <p className='col-title col-item'>パスワード</p>
          <p className='col-item'>非表示</p>
          <p className='col-item col-btn'>
            <button className='edit-btn' onClick={pwOpen}>編集</button>
          </p>
        </div>
        <PwModal>
          <div className='modal'>
          <p>
            <input
              type='password'
              ref={pwOldRef}
              placeholder='現在のパスワード'
            />
          </p>
          <p>
            <input
              type='password'
              onChange={pwSameCheck}
              ref={pwNewRef}
              placeholder='新しいパスワード'
            />
          </p>
          <p>
            <input
              type='password'
              onChange={pwSameCheck}
              ref={pwNew2Ref}
              placeholder='新しいパスワード(確認)'
            />
          </p>
          <p hidden ref={pwSameErrRef} className="error">
            パスワードが同一ではありません。
          </p>
          <p hidden ref={pwSendErrRef} className="error">
            失敗しました。パスワードが間違っている可能性があります。
          </p>
          <p className="modal-btns">
            <button className='edit-btn' onClick={changePw}>決定</button>
            <button className='edit-btn' onClick={pwClose}>キャンセル</button>
          </p>
          </div>
        </PwModal>

        <div className='profile-col'>
          <p className='col-title col-item'>通知</p>
          <p className='col-item'>
            <button className='edit-btn' onClick={requestForToken}>通知を許可</button>
          </p>
          <p className='col-item dummy'/>
        </div>

        <div className='profile-col'>
          <p className='col-title col-item'>ログアウト</p>
          <p className='col-item'>
            <Link to={`/logout`}><button className='edit-btn'>ログアウト</button></Link>
          </p>
          <p className='col-item dummy'/>
        </div>

      </div>
    </div>
  </main>
  )
}

const sendChangeIcon = async (sendFile: File | null | undefined) : Promise<boolean> => {
  if (sendFile != null) {
    let url = HOSTNAME + '/changeIcon'
    const params = new FormData()
    params.append('file', sendFile)

    let response = await fetch(url, {
      credentials: 'include',
      method: "POST",
      body: params
    })
    return response.ok
  }
  return false
}

const sendChangeDn = async (inputRef: React.RefObject<HTMLInputElement>) : Promise<boolean> => {
  if (inputRef.current != null) {
    let url = HOSTNAME + '/changeDisplayName'
    let json = JSON.stringify({'displayName' : inputRef.current.value})

    let response = await fetch(url, {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: json
    })
    return response.ok
  }
  return false
}

const sendChangeUn = async (inputRef: React.RefObject<HTMLInputElement>) : Promise<boolean> => {
  if (inputRef.current != null) {
    let url = HOSTNAME + '/changeUniqueName'
    let json = JSON.stringify({'uniqueName' : inputRef.current.value})

    let response = await fetch(url, {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: json
    })
    return response.ok
  }
  return false
}

const sendChangePw = async (oldPassRef: React.RefObject<HTMLInputElement>, newPassRef: React.RefObject<HTMLInputElement>) : Promise<boolean> => {
  if (oldPassRef.current != null && newPassRef.current != null) {
    let url = HOSTNAME + '/changePassword'
    let json = JSON.stringify({'oldPassword' : oldPassRef.current.value, 'newPassword' : newPassRef.current.value})

    let response = await fetch(url, {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: json
    })
    return response.ok
  }
  return false
}
