import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOSTNAME } from '.';

export const Login = (props : any) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const url = HOSTNAME + '/login'

  useEffect(() => {
    if (props.type === "logout") {
      fetch(url, {
        credentials: 'include',
        method: "POST",
      })
    }
  // eslint-disable-next-line
  }, [])

  const handleSubmit = (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const params = new URLSearchParams()
    params.append('name', username)
    params.append('password', password)

    console.log(params)

    fetch(url, {
      credentials: 'include',
      method: "POST",
      body:params
    })
      .then(response => {
        if (response.ok) {
          navigate('/')
        } else {
          setError('ユーザ名またはパスワードが違います。')
          setLoading(false)
        }
      })
  }

  return (
    <div className="login-content">
      <p className="title center">新星</p>
      <p className="title">Muto Online System CORDial version</p>

      <form onSubmit={ (e) => {
        setLoading(true)
        handleSubmit(e)
      }}>
        <p className='form-input'>
          <input
          type='text'
          name='name'
          placeholder='ユーザ名'
          value={username}
          onChange={(e) => {setUsername(e.target.value)}}
          required />
        </p>
        <p className='form-input'>
          <input
          type='password'
          name='password'
          placeholder='パスワード'
          value={password}
          onChange={(e) => {setPassword(e.target.value)}}
          required />
        </p>
        { loading ?
            <div className='spinner'></div>
          :
          <input className='login-btn' type='submit' value='ログイン' />
        }
      </form>

      <p className='login-error'>{error}</p>
      <p>Tips: 夜は寝た方が良い</p>
    </div>
  )
}
