import React, { KeyboardEvent, useEffect, useState, useRef, ChangeEvent, useContext, createContext } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { Loading, LoadingPart } from './Loading';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { HOSTNAME, VERSION, WS_HOSTNAME } from '.';
import imageCompression from 'browser-image-compression';
import reactStringReplace from 'react-string-replace';
import {GrClose, GrTextAlignCenter, GrTextAlignRight} from 'react-icons/gr';

type Message = {
  id: number
  text: string 
  date: string 
  uniqueName: string 
  displayName: string 
  room: number  
  messageType: number  
  fontSize: number  
  fontColor: string  
  dateSplit: boolean  
}

type User = {
  uniqueName: string
  displayName: string
  iconPath: string
}

type Room = {
  roomName: string
  roomId: number 
}

export const RoomContext = createContext<number>(-1)
export const ErrorContext = createContext<boolean>(false)
export const SetRoomContext = createContext<React.Dispatch<React.SetStateAction<number>>>(()=>{})
export const SetErrorContext = createContext<React.Dispatch<React.SetStateAction<boolean>>>(()=>{})

const formatDate = (s : string) : String => {
  let d = new Date(s.replace(/-/g, '/') + '+0')
  return d.getFullYear() + '/' +
  (d.getMonth()+1).toString().padStart(2, '0') + '/' +
  d.getDate().toString().padStart(2, '0') + ' ' +
  d.getHours().toString().padStart(2, '0') + ':' +
  d.getMinutes().toString().padStart(2, '0')
}

const connect = (setUpdateFlag : Function) => {
  let Socket = new ReconnectingWebSocket(WS_HOSTNAME)
  let pingPongTimer : NodeJS.Timeout | null = null

  const checkUpdate = () => {
    Socket.send('getUpdateDate')
  }

  const checkConnection = () => {
    Socket.send('ping')
    pingPongTimer = setTimeout(() => {
      console.log('再接続を試みます')
      pingPongTimer = null
      Socket.reconnect()
      checkUpdate()
    }, 1000)
  }

  Socket.onopen = () => {
    setInterval(checkConnection, 3000)
    setInterval(checkUpdate, 5000)
    
    Socket.addEventListener('message', (e) => {
      if ((e.data as string).split(':')[0] === 'pong'){
        console.log(e.data)

        if (pingPongTimer) {
          clearTimeout(pingPongTimer)
        }
      } else if ((e.data as string).split(':')[0] === 'updateDate') {
        let [_, ..._date] = (e.data as string).split(':')
        let date = _date.join(':')
        console.log(date)
        setUpdateFlag(date)
      } else {
        setUpdateFlag((new Date()).toLocaleString())
      }
    })
  }
}

const RoomList = (props : any) => {
  const navigate = useNavigate()
  const [roomList, setRoomList] = useState([] as Room[])
  const [error, setError] = useState(false)
  const roomId = useContext(RoomContext)
  const setRoomId = useContext(SetRoomContext)
  const roomParam = props.roomParam

  useEffect(() => {
    const getJoinedRooms = async () => {
      let url = HOSTNAME + "/getJoinedRooms"
      try {
        fetch(url, {
          credentials: 'include'
        })
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
                navigate('/logout')
              } else {
                console.log(response.statusText)
                throw Error("Failed to connect api server")
              }
            } else {
              return response.json()
            }
          })
          .catch(e => {
            console.log('catch server error: ' + e)
            setError(true)
          })
          .then(data => {
            if (data != null) {
              let roomList = JSON.parse(data) as Room[]
              setRoomList(roomList)
              if (roomList.length > 0 && roomId === -1) {
                if (roomParam != null && !Number.isNaN(Number(roomParam))) {
                  setRoomId(Number(roomParam))
                } else {
                  setRoomId(roomList[0].roomId)
                }
              }
            }
          })
      } catch(e) {
        console.log('server error: ' + e)
        setError(true)
      }
    }

    getJoinedRooms()
  // eslint-disable-next-line
  }, [])

  const changeRoom = (room : number) => {
    setRoomId(room)
    navigate(`/chat/${room}`)
  }

  return (
    <div className="room-list">
    {error ? <Loading error={error} /> : null}
    {
      roomList.map(room => 
        <div
          key={room.roomId}
          className={roomId === room.roomId ? "active" : ""}
        >
          <p
            className="room-name"
            onClick={() => changeRoom(room.roomId)}
          >
            {room.roomName}
          </p>
          {roomId === room.roomId ? <FriendList /> : null}
        </div>
      )
    }
    </div>
  )
}

const FriendList = () => {
  const [friends, setFriends] = useState([] as User[])
  const roomId = useContext(RoomContext)

  useEffect(() => {
    if (roomId < 0) {
      return
    }
    let url = HOSTNAME + "/getRoomMembers?roomId=" + roomId
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          console.log(response.statusText)
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (data != null) {
          var parseData = JSON.parse(data) as User[]
          setFriends(parseData)
        }
      })
  }, [roomId])

  const joinCallRoom = () => {
    window.location.href = 'https://api.moscord.xyz/joinCallRoom';
  }

  return (
  <div className="friend-list">
    <div className="friend clickable" onClick={joinCallRoom}>
      <div className="author-icon">
        <i className="bi bi-telephone-fill"></i>
      </div>
    </div>
    {
      friends.map(friend => 
        <div className="friend" key={friend.displayName}>
          <div className="author-icon">
            <img className="icon-img" src={HOSTNAME+'/'+friend.iconPath} alt='' />
          </div>

          <p className="display-name">{friend.displayName}</p>
          <p className="unique-name">{friend.uniqueName}</p>
        </div>
      )
    }
  </div>
  )
}

const MessageList = () => {
  const [messages, setMessages] = useState<Message[] | null>(null)
  const [loadingMessage, setLoadingMessage] = useState(true)
  const [loading, setLoading] = useState(true)
  const [updateFlag, setUpdateFlag] = useState("")
  const [isFirst, setIsFirst] = useState(true)
  const [scrollLockFlag, setScrollLockFlag] = useState<number | null>(null)
  const [iconMap, setIconMap] = useState(new Map<string, string>())
  const roomId = useContext(RoomContext)
  const scrollBottomRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (scrollLockFlag == null) {
      scrollBottomRef?.current?.scrollIntoView(false)
    } else {
      document.getElementById('message' + scrollLockFlag)?.scrollIntoView(true)
    }
    document.getElementsByTagName('main')[0].scrollIntoView(false)

    setLoading(false)

    if (isFirst) {
      setTimeout(() => {
        if (scrollLockFlag == null) {
          scrollBottomRef?.current?.scrollIntoView(false)
        } else {
          document.getElementById('message' + scrollLockFlag)?.scrollIntoView(true)
        }
        document.getElementsByTagName('main')[0].scrollIntoView(false)

        setLoading(false)
        console.log(isFirst)
      }, 300)
    }
    setIsFirst(false)
  }

  window.addEventListener('resize', () => {
    if (window.matchMedia && window.matchMedia('(max-width: 1024px)').matches) {
      scrollToBottom()
    }
  })

  useEffect(() => {
    if (roomId < 0) {
      return
    }

    setLoadingMessage(true)
    setLoading(true)
    setScrollLockFlag(null)

    connect(setUpdateFlag)
    fetch(HOSTNAME + '/getRoomMembers?roomId=' + roomId, {
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          console.log(response.statusText)
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (data != null) {
          var parseData = JSON.parse(data) as User[]
          let im = new Map<string, string>()
          parseData.forEach (e => {
            im.set(e.uniqueName, HOSTNAME+'/'+e.iconPath)
          })
          setIconMap(im)
        }
      })

    }, [roomId])

  useEffect(() => {
    getMessages(true)
  // eslint-disable-next-line
  }, [updateFlag, roomId])

  const getMessages = (isLimited : boolean) => {
    if (roomId < 0) {
      return
    }

    let url = HOSTNAME + '/getMessages?roomId=' + roomId
    if (!isLimited) {
      if (messages != null) {
        setScrollLockFlag(messages[0].id)
      }
      url = HOSTNAME + '/getAllMessages?roomId=' + roomId
    }

    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          console.log(response.statusText)
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (data != null) {
          var parseData = JSON.parse(data) as Message[]
          setMessages(parseData)
        }
        setLoadingMessage(false)
      })
  }

  const formatDateOnlyYearMonthDate = (ds : string) : string => {
    let d = new Date(ds.replace(/-/g, '/') + '+0')
    return (d.getFullYear() + '/' + (d.getMonth()+1) + '/' + d.getDate())
  }

  if (!loadingMessage && messages != null) {

    let beforeDate = formatDateOnlyYearMonthDate(messages[0].date)
    for (let i = 1; i < messages.length; i++) {
      let currentDate = formatDateOnlyYearMonthDate(messages[i].date)
      messages[i].dateSplit = currentDate !== beforeDate
      beforeDate = currentDate
    }

    return (
    <div
      className="message-list"
      onLoad={scrollToBottom}
      style={{visibility: loading ? 'hidden' : 'visible'}}
    >
      <div className="fetch-button-box">
        <button className="fetch-button" onClick={() => {getMessages(false)}}>
          過去の会話を全て取得する
        </button>
      </div>
      {
        messages.map(message => 
          <div
            className="message-outBox"
            key={message.id}
            id={'message'+message.id}
          >
            {message.dateSplit ?
              <div
                className="split-date"
              >
                <hr/>
                <p>{formatDateOnlyYearMonthDate(message.date)}</p>
                <hr/>
              </div>
              :null
            }

            <div
              className="message-box"
            >
              <div className="author-icon">
                <img className="icon-img" src={iconMap.get(message.uniqueName)} alt='' />
              </div>

              <div className="message-Innerbox">
                <p className="message-author">{message.displayName}</p>
                <p className="message-time">{formatDate(message.date)}</p>

                { message.messageType === 0 ? 
                <p className="message-text" style={{
                  fontSize: message.fontSize,
                  color: message.fontColor
                }}>
                  {processText(message.text)}
                </p> :
                <ShowFile src={message.text} />
                }
              </div>
            </div>
          </div>
        )
      }
      <div ref={scrollBottomRef} />
    </div>
    )
  } else if (!loadingMessage) {
    return (<div className="message-list"></div>)
  } else {
    return (<Loading />)
  }
}

const processText = (text: string) => {
  let emoji = /^(\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F)$/gu;
  let url = /(https?:\/\/\S+)/g
  if (text.match(emoji)) {
    return <span className="Emoji">{text}</span>
  }
  return reactStringReplace(text, url, (match, i) =>(
    <a
      key={i}
      href={match}
      target="_blank"
      rel="noreferrer"
      className="text-link"
    >
      {match}
    </a>
  ))
}

const ShowFile = (props : any) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const navigate = useNavigate()

  const defaultImage = () => {
    if (imgRef.current != null) {
      imgRef.current.src = 'file-image.png'
      imgRef.current.width = 50 
    }
  }
  const img = new Image()
  img.src = HOSTNAME + '/thumbnail/' + props.src

  const modalToggle = () => {
    navigate(`/file/${props.src}`)
  }

return (
  <div>
    <img
    src={img.src}
    className='message-img'
    loading='lazy'
    onClick={modalToggle}
    onError={defaultImage}
    ref={imgRef}
    style={{backgroundImage: 'file-image.png'}}
    alt=""
    />
  </div>
)
}

const InputBox = () => {
  const [text, setText] = useState('')
  const [textDummy, setTextDummy] = useState('\u200b')
  const inputBoxRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const imgBoxRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  let loadingText = false
  let loadingFile = false
  const [sendFile, setSendFile] = useState<File | null>()
  const [fontSize, setFontSize] = useState('1.0rem')
  const [fontColor, setFontColor] = useState('#b3b3b3')
  const roomId = useContext(RoomContext)

  const sendMessage = () => {
    if (loading || roomId < 0) {
      return
    }
    setLoading(true)

    if (inputRef.current != null &&
      inputRef.current.files != null &&
      inputRef.current.files.item(0) != null
    ) {
      let url = HOSTNAME + '/addFileMessage'
      const params = new FormData()
      params.append('room', `${roomId}`)
      if (sendFile != null && sendFile.size !== 0) {
        params.append('file', sendFile, inputRef.current.files.item(0)?.name)
        console.log(sendFile)
      } else {
        params.append('file', inputRef.current.files.item(0) as File)
      }
      loadingFile = true

      fetch(url, {
        credentials: 'include',
        method: "POST",
        body: params
      })
        .then(response => {
          if (!response.ok) {
            console.log(response.statusText)
          } else {
            clearImage()
          }
          loadingFile = false
          setLoading(loadingFile || loadingText)
        })
    } else {
      loadingFile = false
    }

    let message = text
    if (message !== '') {
      let url = HOSTNAME + '/addMessage'
      let json = JSON.stringify({
        'room': roomId,
        'text' : message,
        'fontSize': fontSize,
        'fontColor': fontColor
      })
      setText('')
      setTextDummy('\u200b')
      loadingText = true

      fetch(url, {
        credentials: 'include',
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: json
      })
        .then(response => {
          if (!response.ok) {
            console.log(response.statusText)
            setText(message)
            setTextDummy(message + '\u200b')
          } else {
            setText('')
            setTextDummy('\u200b')
          }
          loadingText = false
          setLoading(loadingFile || loadingText)
        })
    } else {
      loadingText = false
      setLoading(loadingFile || loadingText)
    }
  }

  const checkAndSend = (e : KeyboardEvent) => {
    if ((e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 10) && e.ctrlKey) {
      sendMessage()
    }
  }

  const textAreaAutoResize = (e : ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
    setTextDummy(e.target.value + '\u200b')
    scrollToEnd()
  }

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null && imgRef.current != null && inputRef.current != null && imgBoxRef.current != null) {
      imgBoxRef.current.hidden = false

      imgRef.current.onerror = () => {
        if (imgRef.current != null && inputRef.current != null && inputRef.current.files?.length !== 0) {
          imgRef.current.src = 'file-image.png'
        }
      }

      if (event.target.files.length === 0) {
        imgRef.current.src = ''
        inputRef.current.value = ''
        imgBoxRef.current.hidden = true
        return
      }

      let fr = new FileReader()
      try {
        fr.readAsDataURL(event.target.files[0])
        fr.onload = async () => {
          setLoading(true)
          if (event.target.files != null &&
              event.target.files[0] != null &&
              imgRef.current != null &&
              event.target.files[0].type.startsWith('image/') &&
              inputRef.current != null)
          {
            const img = event.target.files[0]
            try {
              const cf = await imageCompression(img, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1024
              })
              setSendFile(cf)
              const url = await imageCompression.getDataUrlFromFile(cf)
              imgRef.current.src = url
            } catch (e) {
              console.log(e)
              imgRef.current.src = fr.result as string
            }
          }
          setLoading(false)
        }
      } catch {
        if (imgRef.current != null && inputRef.current?.files?.length !== 0) {
          imgRef.current.src = 'file-image.png'
        }
      }
    }
  }

  const fileUpload = () => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  }

  const clearImage = () => {
    if (imgRef.current != null && inputRef.current != null && imgBoxRef.current != null) {
      imgRef.current.src = 'loading.png'
      inputRef.current.value = ''
      imgBoxRef.current.hidden = true
      setSendFile(null)
    }
  }

  const scrollToEnd = () => {
    inputBoxRef?.current?.scrollIntoView(false)
  }

  let fontSizes: string[] = [];
  for (let i = 0; i < 29; i++) {
    fontSizes[i] = ((i + 1) * 0.1).toFixed(1)
  }

  return (
    <div
      className={loading ? 'input-box not-touch' : 'input-box'}
      ref={inputBoxRef}
    >
      { loading ? <LoadingPart /> : null }
      <div
        className="font-option"
      >
        <select className="font-size-select" onChange={(e) => {
          setFontSize(e.target.value)
        }}
        value={fontSize}
        >
          {fontSizes.map((s) => {
            return (<option key={s} value={s+'rem'}>{s}</option>)
          })}
        </select>
        <input type="color"
        className="color-select"
        value={fontColor}
        onChange={(e) => {
          setFontColor(e.target.value)
        }} />
      </div>
      <div
        hidden
        className="img-preview-box"
        ref={imgBoxRef}
      >
        <i className="bi bi-x" onClick={clearImage}></i>
        <img ref={imgRef} src="loading.png" height="100" className="img-preview" alt="" />
      </div>
      <div className="inline">
        <button className="message-button file-upload-button" onClick={fileUpload}>
          <i className="bi bi-plus-lg"></i>
        </button>
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={onFileInputChange}
          accept="image/*"
        />
        <div className="FlexTextarea">
          <div 
            className="FlexTextarea__dummy"
            aria-hidden="true"
            style={{fontSize: fontSize, color: fontColor}}
          >
            {textDummy}
          </div>
          <textarea
            name="message"
            placeholder="メッセージを送信" className="FlexTextarea__textarea message-input"
            onKeyDown={checkAndSend}
            onChange={textAreaAutoResize}
            onClick={scrollToEnd}
            onCompositionStart={scrollToEnd}
            value={text}
            style={{fontSize: fontSize, color: fontColor}}
          />
        </div>
        <button className="message-button submit-button" onClick={sendMessage}>
          <i className="bi bi-arrow-right"></i>
        </button>
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <div className="footer">
      <p className="credit">Muto Online System<br/>CORDial version {VERSION}</p>
      <p><Link to={`/profile`}><i className="bi bi-person-fill"></i></Link></p>
    </div>
  )
}

const ImgModal = (props : any) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const defaultImage = () => {
    if (imgRef.current != null) {
      imgRef.current.src = 'file-image.png'
      imgRef.current.width = 50 
    }
  }

  const modalToggle = () => {
    navigate("/")
  }

  let filename = props.src;
  if (filename == null) {
    return (
      <div />
    )
  }

  return (
    <div
    className="modal-img-view"
    ref={modalRef}
    onClick={(e) => {if(e.target === e.currentTarget) modalToggle()}}
    >
      <img
      src={HOSTNAME + '/file/' + filename}
      loading='lazy'
      onError={defaultImage}
      ref={imgRef}
      style={{backgroundImage: 'file-image.png'}}
      alt=""
      />
      <button className='img-close-button' onClick={modalToggle}><GrClose /></button>
    </div>
  )
}

export const Main = () => {
  const [sideVisible, setSideVisible] = useState(false)
  const [roomId, setRoomId] = useState(-1)

  const {src} = useParams()
  const {room} = useParams()

  const navigate = useNavigate()

  const toggleOnclick = () => {
    if (sideVisible) {
      navigate(`/chat/${roomId}`)
    } else {
      navigate('/side')
    }
  }

  useEffect(() => {
    setSideVisible(false)
  }, [roomId])

  const pathname = useLocation().pathname
  useEffect(() =>{
    setSideVisible(pathname === '/side' ? true : false)
  }, [pathname])

  return (
    <main>
      <div className="toggle-btn" onClick={toggleOnclick}>
        <i className="bi bi-list"></i>
      </div>
      <RoomContext.Provider value={roomId}>
        <div className={sideVisible ? 'side' : 'side hide'}>
          <SetRoomContext.Provider value={setRoomId}>
            <RoomList roomParam={room} />
            <Footer />
          </SetRoomContext.Provider>
        </div>
        <div className="message">
          <div className="message-list">
          </div>
          <MessageList />
          <ImgModal src={src} />
          <InputBox />
        </div>
      </RoomContext.Provider>
    </main>
  )
}
